import React from 'react';

const Privacy = () => (
  <div className="modal modal--open grid-container privacy">
    <div className="content">
      <div className="container container__privacy-policy">
        <div className="content white-theme">
          <div className="privacy grid-container">
            <div className="container__content">
              <h1>Privacy Policy</h1>
              <div className="text">
                <p>This Privacy Policy of&nbsp;Personal Data (hereinafter&nbsp;— Policy) applies to&nbsp;all
                  information that «FEED 64» LTD (INN 04445034, legal address: 10101010, Republic
                  of&nbsp;Armenia, Armavir, Aygek str., 4DZ, house, BN15) (hereinafter&nbsp;— the Administration
                  of&nbsp;the site) can receive about the User during their use of&nbsp;the site on&nbsp;the Internet
                  at&nbsp;<a className="underline" href="https://feed-64.com">https://feed-&nbsp;64.com</a> (hereinafter referred to&nbsp;as&nbsp;the
                  Site).</p>
                <p>Use of&nbsp;<a className="underline" href="https://feed-64.com">https://feed-&nbsp;64.com</a> and the feedback forms posted
                  on&nbsp;it&nbsp;means the unconditional consent of&nbsp;the User to&nbsp;this Policy and the terms
                  of&nbsp;processing their personal information indicated in&nbsp;it; if&nbsp;the User does not agree
                  to&nbsp;these terms, he/she must refrain from using the Site.</p>
              </div>
              <div className="text">
                <h3>1. GENERAL PROVISIONS</h3>
                <p>1.1 For the purposes of&nbsp;this Policy, personal information of&nbsp;the User shall mean</p>
                <p>1.1.1 Personal information, which the User provides about himself/herself when filling feedback forms
                  (sending applications, messages, etc.), including personal data of&nbsp;the User (name, e-mail, phone
                  number). Obligatory information for providing Services is&nbsp;marked in&nbsp;a&nbsp;special way. Other
                  information is&nbsp;provided by&nbsp;the User at&nbsp;his discretion.</p>
                <p>1.1.2 The data which are automatically transmitted to&nbsp;the Site in&nbsp;the course of&nbsp;their use
                  with the software installed on&nbsp;the User’s device, including cookie data, IP-address,
                  information about User’s browser (or&nbsp;other program with which you access the Services),
                  technical characteristics of&nbsp;the equipment and software used by&nbsp;the User, date and time
                  of&nbsp;access to&nbsp;the Services, requested pages addresses and other similar information.</p>
                <p>1.2 This Privacy Policy only applies to&nbsp;the Site. The Site has no&nbsp;control over and is&nbsp;not
                  responsible for the websites of&nbsp;third parties, to&nbsp;which the User can go&nbsp;through the links
                  available on&nbsp;the Site.</p>
                <p>1.3 The Site does not verify the accuracy of&nbsp;the personal information provided by&nbsp;the User and
                  does not exercise control over the User’s competence. The site assumes that the User is&nbsp;legally
                  capable and provides accurate and sufficient personal information.</p>
              </div>
              <div className="text">
                <h3>2. PURPOSES OF&nbsp;PROCESSING OF&nbsp;PERSONAL INFORMATION OF&nbsp;THE USERS</h3>
                <p>2.1 The site only collects and stores personal information that is&nbsp;necessary for marketing
                  activities and providing services to&nbsp;the User of&nbsp;the site <a className="underline" href="https://feed-64.com">https://feed-&nbsp;64.com</a></p>
                <p>2.2 The Site processes the User’s personal information for the following purposes:</p>
                <p>2.2.1. Identification of&nbsp;the User, for the purposes of&nbsp;conclusion, execution and termination
                  of&nbsp;civil law contracts.</p>
                <p>2.2.2 Establishment of&nbsp;feedback with User, including sending information about the services,
                  as&nbsp;well as&nbsp;for conducting other marketing activities by&nbsp;the Administration of&nbsp;the
                  Website.</p>
                <p>2.2.3 Confirming the accuracy and completeness of&nbsp;personal information provided by&nbsp;the User.
                </p>
                <p>2.2.4 Transferring data to&nbsp;third parties for the purposes of&nbsp;the Administration of&nbsp;the
                  site.</p>
              </div>
              <div className="text">
                <h3>3. TERMS OF&nbsp;PROCESSING OF&nbsp;PERSONAL INFORMATION OF&nbsp;USERS AND ITS TRANSFER TO&nbsp;THIRD
                  PARTIES</h3>
                <p>3.1 The website shall store the User’s personal information in&nbsp;the site’s database,
                  which is&nbsp;stored on&nbsp;the server of&nbsp;the hosting provider, located in&nbsp;the Republic
                  of&nbsp;Armenia.</p>
                <p>3.2 With respect to&nbsp;personal information of&nbsp;the User its confidentiality shall
                  be&nbsp;preserved, except for cases when the User voluntarily provides information about himself for
                  public access to&nbsp;the general public. When using particular services, the User agrees that certain
                  portions of&nbsp;their personal information will become publicly available.</p>
                <p>3.3 The Site has the right to&nbsp;transfer the User’s personal information to&nbsp;third parties
                  in&nbsp;the following cases:</p>
                <p>3.3.1. The User has expressed consent to&nbsp;such actions.</p>
                <p>3.3.2 The transfer is&nbsp;necessary for the performance of&nbsp;the contract with the User.</p>
                <p>3.3.3 The transfer is&nbsp;required by&nbsp;Armenia or&nbsp;other applicable laws under the procedure
                  established by&nbsp;law.</p>
                <p>3.4 The Website Administration takes necessary organizational and technical measures for protection
                  of&nbsp;the User’s personal information from unauthorized or&nbsp;accidental access, destruction,
                  change, blocking, copying, distribution, as&nbsp;well as&nbsp;from other unlawful actions of&nbsp;third
                  parties, including: installed anti-virus software.</p>
              </div>
              <div className="text">
                <h3>4. USER RIGHTS</h3>
                <p>4.1 The User has the right:</p>
                <p>4.1.1. withdraw consent to&nbsp;the processing of&nbsp;personal data by&nbsp;sending a&nbsp;corresponding
                  application to&nbsp;email <a className="underline" href="mailto:feed64studio@gmail.com">feed64studio@gmail.com</a></p>
              </div>
              <div className="text">
                <h3>5. OBLIGATIONS OF&nbsp;THE SITE ADMINISTRATION</h3>
                <p>5.1 The Website Administration is&nbsp;obliged&nbsp;to:</p>
                <p>5.1.1. Use the received information solely for the purposes specified in&nbsp;this Privacy Policy.</p>
                <p>5.1.2 Keep confidential information secret, and not disclose it&nbsp;without prior written permission
                  of&nbsp;the User, as&nbsp;well as&nbsp;not sell, exchange, publish or&nbsp;disclose in&nbsp;any other way
                  the User’s personal information, except as&nbsp;provided in&nbsp;this Privacy Policy.</p>
                <p>5.1.3 Take precautions to&nbsp;protect the confidentiality of&nbsp;Your Personal Data in&nbsp;accordance
                  with the procedure generally used for the protection of&nbsp;such information in&nbsp;the existing
                  business turnover.</p>
                <p>5.1.4&nbsp;To perform blocking of&nbsp;personal data, related to&nbsp;the relevant User, from the moment
                  of&nbsp;application of&nbsp;the User or&nbsp;its legal representative, or&nbsp;authorized agency for
                  protection of&nbsp;human subjects of&nbsp;personal data for the period of&nbsp;inspection in&nbsp;case
                  of&nbsp;deection of&nbsp;unreliable personal data or&nbsp;unlawful acts.</p>
              </div>
              <div className="text">
                <h3>6. LIABILITY OF&nbsp;THE PARTIES</h3>
                <p>6.1 The Website Administration, if&nbsp;it&nbsp;fails to&nbsp;perform its obligations, shall
                  be&nbsp;liable for losses incurred by&nbsp;the User in&nbsp;connection with the unauthorized use
                  of&nbsp;personal data, in&nbsp;accordance with the legislation of&nbsp;the Republic of&nbsp;Armenia.</p>
                <p>6.2&nbsp;In case of&nbsp;loss or&nbsp;disclosure of&nbsp;confidential information, the Website
                  Administration shall not be&nbsp;responsible if&nbsp;the confidential information</p>
                <p>6.2.1. Became public domain before its loss or&nbsp;disclosure.</p>
                <p>6.2.2. Was received from a&nbsp;third party prior to&nbsp;its receipt by&nbsp;the Site Administration.
                </p>
                <p>6.2.3. has been disclosed with the consent of&nbsp;the User.</p>
              </div>
              <div className="text">
                <h3>7. DISPUTE RESOLUTION</h3>
                <p>7.1 Before filing a&nbsp;lawsuit in&nbsp;court for any disputes arising out of&nbsp;relations between the
                  User and the Website Administration, it&nbsp;is&nbsp;obligatory to&nbsp;file a&nbsp;complaint.</p>
                <p>7.2 The recipient of&nbsp;the claim shall within thirty calendar days from the date of&nbsp;receipt
                  of&nbsp;the claim notify the claimant in&nbsp;writing about the results of&nbsp;consideration of&nbsp;the
                  claim.</p>
                <p>7.3&nbsp;If the agreement is&nbsp;not reached, the dispute will be&nbsp;transferred to&nbsp;the court
                  in&nbsp;accordance with the current legislation of&nbsp;the Republic of&nbsp;Armenia.</p>
                <p>7.4 The current legislation of&nbsp;the Republic of&nbsp;Armenia shall apply to&nbsp;this Privacy Policy
                  and the relations between the User and the Website Administration.</p>
              </div>
              <div className="text">
                <h3>8. ADDITIONAL PROVISIONS</h3>
                <p>8.1. The Website Administration has the right to&nbsp;change this Privacy Policy without the User’s
                  consent.</p>
                <p>8.2. A&nbsp;new Privacy Policy comes into effect upon its posting on&nbsp;the Website, unless otherwise
                  stipulated by&nbsp;the new Privacy Policy version.</p>
                <p>8.3 Any suggestions or&nbsp;questions on&nbsp;this Privacy Policy should be&nbsp;reported to&nbsp;the
                  e-mail&nbsp;— <a className="underline" href="mailto:feed64studio@gmail.com">feed64studio@gmail.com</a></p>
                <p>8.4 The current Privacy Policy is&nbsp;posted on&nbsp;the page&nbsp;at: <a className="underline" href="https://feed-&nbsp;64.com/privacy">https://feed-&nbsp;64.com/privacy</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Privacy;
